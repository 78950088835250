* {
	margin: none;
}

.diagonal-box {
	position: absolute;
	z-index: -1;
	top: -300px;
	right: 0;
	left: 0;
	bottom: 750px;
	background-image: linear-gradient(45deg, #fa9600, #bc2bff);
	transform: skewY(-11deg);
}

.content {
	position: relative;
	width: 100%;
	height: 20vh;
	margin: 0 auto;
}

.nav-bar {
	display: flex;
	flex-direction: row;
	padding: 3rem;
	justify-content: space-between;
	align-items: center;
}


.header-logo {
	margin: 0;
	font-size: 3vw;
	text-shadow: 3px 2px 2px rgb(5, 5, 5);
	cursor: pointer;
}

.nav {
	display: flex;
	flex-direction: row;
	justify-content: center;
}


.nav .link {
	margin: 0 2rem;
	list-style: none;
	font-size: 1.6rem;
	font-weight: 500;
	text-shadow: 0.5px 1px 1px black;
	color: white;
	text-decoration: none;
}

.nav li {
	text-decoration: none;
}

.hamburger {
	display: none;
	cursor: pointer;
}

.hero-section {
	margin-top: 4vh;
	padding: 2rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.hero-section a {
	color: white;
	text-decoration: none;
}

.hero-body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 80vh;
}

.hero-body h1 {
	font-size: 8vh;
	margin-bottom: 0;
}

.hero-body h3 {
	margin-top: 10px;
	font-size: 1.6vh;
}

.hero-section img {
	padding: 5rem;
	height: 50vh;
	width: 50vh;
}

.hero-buttons {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 2rem;
}

.hero-button {
	width: 22rem;
	height: 5rem;
	border-radius: 2rem;
	border-style: none;
	margin-right: 5vw;
	font-size: 2rem;
	background-color: #bc2bff;
	font-family: 'Poppins', sans-serif;
	color: white;
}

.hero-button:hover {
	background-color: #8420b3;
	cursor: pointer;
}

.info-card {
	width: 30vh;
	height: 55vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: white;
	text-align: center;
	padding: 2.7rem;
	border-radius: 1.2rem;
	margin: 3rem;
}

.card-image {
	width: 10rem;
	height: 7.7rem;
	align-self: center;
}

.info-section {
	margin-top: 50vh;
	margin-bottom: 40vh;
	width: 100%;
	height: 50vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: 'Poppins', sans-serif;
}

.cards {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.member-section {
	background-color: #bc2bff;
	min-height: 60vh;
	display: flex;
	flex-direction: row;
	flex-flow: wrap;
	align-items: center;
	justify-content: space-around;
}

.card-container {
	width: 37.5rem;
	height: 25rem;
	transform-style: preserve-3d;
	margin: 1rem;
}

.card {
	height: 100%;
	width: 100%;
	transition: transform 0.6s ease-in-out;
	transform-style: preserve-3d;
	position: relative;
}

.card figure {
	color: white;
	position: absolute;
	transform-style: preserve-3d;
	height: 100%;
	width: 100%;
	margin: 0;
	transition: 0.6s ease-in-out;
	backface-visibility: hidden;
	transform: perspective(500px) rotateY(0deg);
}

.card .front {
	color: rgb(255, 255, 255);
	background-size: cover;
	background-position: center;
	text-shadow: 2px 2px 2px black;
	box-shadow: 2px 1px 3px black;
}

.header-info h1,
h3 {
	margin: 0;
}

.card:hover .front {
	transform: perspective(500px) rotateY(180deg);
}

.card .back {
	background-image: linear-gradient(45deg, #fa9600, #bc2bff);
	color: rgb(255, 255, 255);
	transform: perspective(500px) rotateY(180deg);
	text-shadow: 2px 2px 2px black;
	box-shadow: 2px 1px 3px black;
	display: flex;
	flex-direction: column;
	align-items: space-around;
	justify-content: flex-start;
	text-align: center;
	font-family: 'Open Sans', sans-serif;
	font-size: 1.0rem;
}

.card .back h2{
	margin: 0;
}

.card:hover .back {
	transform: perspective(500px) rotateY(360deg);
}

.card .header {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: 0.8rem;
	align-self: center;
}

.header h1 {
	font-size: 1.7rem;
	margin-right: 1rem;
}

.link-img {
	width: 3rem;
	height: 3rem;
}

.link-img:hover {
	cursor: pointer;
}

.links {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: 0.8rem;
	align-self: center;
}

@media (max-width: 1700px){
	.card-image {
		width: 10vw;
		height: 10vw;
		align-self: center;
	}
	body {
		width: 100%;
	}
	.nav-bar{
		visibility: hidden;
		display: none;
	}
	.hand {
		visibility: hidden;
		display: none;
	}
	.hero-section {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 40vw;
	}
	.hero-body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.info-section {
		height: 100vh;
		width: 100vw;
		margin-top: 50vh;
		margin-bottom: 30vh;
		text-align: center;
	}

	.info-section h1 {
		margin-bottom: 0;
		padding: 0 2rem;
		font-size: 4vw;
	}

	.info-card {
		padding-top: 5vw;
		width: 60vw;
		height: 75vw;
		font-size: 2.3vw;
		margin: 5vw;
	}

	.cards {
		flex-direction: row;
		flex-flow: wrap;
		justify-content: center;
		align-items: center;
		width: 100vw;
		margin-bottom: 30vh;
		margin-top: 30vh;
	}
	.member-section {
		margin-top: 10vh;
	}
	.hero-body {
		text-align: center;
		width: 100%;
		font-size: 15vw;
	}
	.hero-buttons {
		flex-direction: row;
		flex-flow: wrap;
		justify-content: center;
	}
	.hero-button {
		width: 60vw;
		height: 10vh;
		margin-top: 1vh;
		margin-right:0;
	}

	.card .back {
		font-size: 3.5vw;
	}
}

@media (min-width: 650px){

	.info-card {
		padding-top: 5vw;
		width: 50vw;
		height: 45vw;
		font-size: 1.3vw;
		margin: 5vw;
		margin-bottom: 10vw;
	}

	.info-card h1{
		font-size: 3.1vw;
	}

	.info-section {
		margin-bottom: 20vw;
	}

	.member-section {
		margin-top: 50vw;
	}



}

@media (min-width: 1200px) {
	.info-card {
		width: 20vw;
		height: 40vw;
		display: flex;
		flex-direction: column;
		align-items: center;
		color: white;
		text-align: center;
		padding: 2.7rem;
		border-radius: 1.2rem;
		margin: 3rem;
		font-size: 0.8vw;
	}

	.hero-section {
		margin: 0;
	}

	.info-card h1 {
		font-size: 1.8vw;
	}

	.info-section {
		margin: 0;
	}

	.member-section {
		margin: 0;
	}
	
	.card-image {
		width: 5vw;
		height: 5vw;
		align-self: center;
	}
}

@media (min-width: 1700px) {

	.hero-section {
		margin-bottom: 20vw;
	}

	.info-section {
		margin-bottom: 20vw;
	}

}
